.filter-container label,
.filter-container input {
  flex: 1;
  height: 30px;
  width: 100%;
  padding-top: 6px;
}

.search-input {
  width: 150px;
}

.desktop-table {
  display: block;
}

.mobile-list {
  display: none;
}

@media (max-width: 768px) {
  .desktop-table {
    display: none;
  }

  .mobile-list {
    display: block;
  }

  .customer-info p,
  .customer-info h4 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}