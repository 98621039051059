header {
  background-color: #333;
  color: #fff;
  padding: 10px;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline-block;
}

nav ul li a {
  color: #fff;
  display: block;
  padding: 10px 15px;
  text-decoration: none;
}

.dropdown {
  display: inline-block;
}

.menu-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  align-items: center;
  margin-left: 10px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content {
  display: block;
}

.section {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
