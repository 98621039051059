body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.filter-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.filter-container label,
.filter-container input,
.filter-container select {
  flex: 1;
  height: 30px;
  width: fit-content;
  padding-top: 6px;
}

.pending-filter-inputs input {
  flex: 1;
  height: 22px !important;
  width: fit-content;
  padding-top: 6px !important;
}

.filter-container button {
  height: 30px;
  margin-left: 10px;
}

.pending-filter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
}

.pending-filter-container label{
  flex: 1;
  height: 29px;
  width: fit-content;
  padding-top: 0px;
}

.pending-filter-container select{
  flex: 1;
  height: 30px;
  width: fit-content;
  padding-top: 6px;
}

.pending-filter-inputs {
  display: flex;
  gap: 8px;
}

h2 {
  text-align: center;
  color: #333;
}

form {
  margin-bottom: 20px;
}

form h4 {
  margin-bottom: 10px;
}

input[type="date"],
input[type="text"],
select {
  padding: 8px;
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

label {
  margin-top: 10px;
  display: block;
  font-weight: bold;
}

table {
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #000;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.print-table-btn {
  margin-top: 48px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

/* table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
} */


.view-details-btn {
  width: fit-content;
}

@media print {
  .view-details-btn-hide-on-print {
    display: none !important;
  }
}
