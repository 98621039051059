body {
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
  }
  
  form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
  }
  
  input[type="text"],
  input[type="date"],
  input[type="number"] {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  table {
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #000;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .red-text {
    color: red;
  }

  .installmentScheduleTable {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.seizedDetailsContainer,
.customerDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  max-width: 700px;
  margin-left: 200px;
}

.seizedDetailsContainer h4 {
  margin-bottom: 0px;
}

.customerDetailsTable {
  width: 100%;
  max-width: 700px;
  margin: auto;
  margin-left: 200px;
  border-collapse: collapse;
}

.customerDetailsTable td {
  padding: 8px;
  border: 1px solid #131313;
}

.customerDetailsTable td:first-child {
  width: 150px;
}

.red-text {
  color: red;
}

.file-status-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  font-size: 100px;
  color: rgba(255, 0, 0, 0.2);
  pointer-events: none;
  z-index: -1;
}

.file-status-container.closed {
  color: rgba(255, 0, 0, 0.548);
}

